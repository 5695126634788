<template>
    <div :class="`m-3 p-2 wrapper  wrapper--200`">
        <h3 class="text-center">Dagen verkocht lijst</h3>
        <hr>
        <Loading v-if="loading" />
        <Tabs v-if="!loading">
            <Tab :title="group_1_name">
                <DaysVDistributionTable :table_data="group_1" :table_kind="group_1_name" />
            </Tab>
            <Tab :title="group_2_name">
                <DaysVDistributionTable :table_data="group_2" :table_kind="group_2_name" />
            </Tab>
            <Tab :title="group_3_name">
                <DaysVDistributionTable :table_data="group_3" :table_kind="group_3_name" />
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import DaysVDistributionTable from "@/components/DaysVDistributionTable.vue";

export default {
    props: ["bu"],
    components: { Loading, Tabs, Tab, DaysVDistributionTable },
    data: () => ({
        group_1: null,
        group_2: null,
        group_3: null,
        group_1_name: null,
        group_2_name: null,
        group_3_name: null,
        loading: null,
    }),
    created() {
        this.getData(this.bu);
    },
    watch: {
        bu(newbu) {
            this.getData(newbu)
        }
    },
    methods: {
        getData(bu) {
            this.loading = true;
            if (this.bu === 'trucks') {
                this.group_1_name = 'Bob Voskuil';
                this.group_2_name = 'Pieter Voskuil'
                this.group_3_name = 'Dave Nijssen';
            }
            if (this.bu === 'trailers'){
                this.group_1_name = 'Paul Haenen'
                this.group_2_name = 'Pieter Voskuil';
                this.group_3_name = 'Dave Nijssen';
            }
            if (this.bu === 'vans') {
                this.group_1_name = '30-60 dagen'
                this.group_2_name = '60-90 dagen'
                this.group_3_name = '> 90 dagen'
            }
            request(`sales-orders-daysv-distribution/${bu}`, "GET").then(({ group_1, group_2, group_3 }) => {
                this.group_1 = group_1;
                this.group_2 = group_2;
                this.group_3 = group_3;
                this.loading = false;
            });
        },
    },
};
</script>