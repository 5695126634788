<template>
    <div class="p2">
        <button class="m2 employee_download" @click="createExcel(table_data)">Uitdraai maken</button>
        <table class="w-100 table--default table__border--200">
            <thead class="table__head--200">
                <tr>
                    <th class="table__head--text">Item</th>
                    <th class="table__data--right table__head--text">Klant</th>
                    <th class="table__data--right table__head--text">Vraagprijs</th>
                    <th class="table__data--right table__head--text">Nettoprijs</th>
                    <th class="table__data--right table__head--text">Betaald</th>
                    <th style="width: 110px" class="table__data--right table__head--text">Datum</th>
                    <th style="width: 200px" class="table__data--right table__head--text">Verkoper</th>
                    <th style="width: 100px" class="table__data--right table__head--text">Dagen V</th>
                    <th style="width: 600px;" class="table__data--right table__head--text">Laatste notitie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
                    <td class="table__cell--default text-center">
                        <ImageHover :itemnummer="item.itemno" :bu="item.bu" />
                    </td>
                    <td class="table__cell--default text-center">
                        <router-link :to="{ name: 'klantpagina', params: { id: item.klant } }">{{ item.naam
                            }}</router-link>
                    </td>
                    <td class="table__data--right table__cell--default">
                        € {{ item.prijs }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        € {{ item.nettoprijs }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        € {{ item.Betaald }}
                    </td>
                    <td class="text-center table__cell--default">
                        {{ item.datum }}
                    </td>
                    <td class="table__cell--default">
                        {{ item.verkopernaam }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        {{ item.dagenverkocht }}
                    </td>
                    <td class="table__cell--default">
                        <span v-if="item.laatsteNotitie"><strong>{{ item.datumNotitie }}</strong>: {{ item.laatsteNotitie }}</span>
                        <button class="daysVlist__postnote--button" @click="getItemnoFromRow(item.itemno)"> + </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <b-modal id="create" ref="note_modal">
                <template #modal-header>
                    <h2>Voeg notitie toe</h2>
                </template>
                <textarea class="form-control" id="note_field" rows="3" v-model.lazy="note_model"></textarea>
                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" size="sm" class="float-right" @click="postNote">
                            Toevoegen
                        </b-button>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue"
import { msalInstance } from "vue-msal-browser";
import request from '@/functions/request.js'
import hasIndex from '@/functions/hasIndex/hasIndex.js'
import * as XLSX from "xlsx";

export default {
    components: { ImageHover },
    props: {
        table_data: [Object, Array],
        table_kind: [String],
    },
    data: () => ({
        note_model: "",
        add_note_itemno: null,
    }),
    methods: {
        getItemnoFromRow(itemno) {
            this.$refs["note_modal"].show();
            this.add_note_itemno = itemno;
        },

        postNote() {
              const posturl = "post-note";
            const data = {
                itemno: this.add_note_itemno,
                gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
                datum: this.moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
                aantekeningen: this.note_model,
            };
              request(posturl, "POST", data).then((values) => {
                let toastProps = {};
                if (hasIndex(values.message, "toegevoegd")) {
                  toastProps = {
                    message: values.message,
                    type: "success",
                  };
                  this.$refs["note_modal"].hide();
                  location.reload();
                } else {
                  toastProps = {
                    message: values.error,
                    type: "danger",
                  };
                  this.$refs["note_modal"].hide();
                }

                this.$store.dispatch("addNotification", toastProps);
              });
        },

        createExcel(table_data) {
            let DaysV_list = [];
            table_data.forEach((i) => {
                DaysV_list.push({
                    "Item": i.itemno,
                    "Klant": i.klant,
                    "Vraagprijs": i.prijs,
                    "Nettoprijs": i.nettoprijs,
                    "Betaald": i.Betaald,
                    "Datum": i.datum,
                    "Verkoper": i.verkopernaam,
                    "DagenV": i.dagenverkocht,
                    "Laatste notitie": i.laatsteNotitie,
                    "Datum laatste notitie": i.datumNotitie,
                });
            });

            var telWS = XLSX.utils.json_to_sheet(DaysV_list);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                wb,
                telWS,
                `DagenV_${this.table_kind}`
            );
            XLSX.writeFile(
                wb,
                `DagenV_${this.table_kind}.xlsx`
            );
        },
    }

}
</script>